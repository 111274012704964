<script setup lang="ts">
import type { DashboardSubscription } from '@/api/types.gen'

defineProps<{
  subscription: DashboardSubscription
}>()

const emit = defineEmits<{
  (e: 'close'): void
}>()

const isOpen = ref<boolean>(true)

const cancel = () => {
  isOpen.value = false
}

const emitClose = () => {
  emit('close')
}
</script>

<template>
  <VDialog
    class="welcome-dialog"
    v-model="isOpen"
    @update:model-value="emitClose"
  >
    <!-- Dialog close btn -->
    <DialogCloseBtn @click="cancel" />

    <VCard>
      <VCardTitle class="welcome-card-title">
        <span class="text-h3" style="padding-top: 2px;">{{ $gettext('Welcome to Entry!') }}</span>
      </VCardTitle>

      <VCardText class="welcome-card-text">
        <p class="text-bold">
          {{ $gettext('Ladies and Gentlemen,') }}
        </p>
        <p>
          {{ $gettext('We look forward to having you on board and helping you to manage your locations and technicians efficiently. With Entry, you always have an overview and can automate your field and office processes in no time at all.') }}
        </p>
      </VCardText>

      <VCardActions class="welcome-card-actions">
        <VBtn
          color="primary"
          variant="flat"
          @click="cancel"
        >
          {{ $gettext('Continue with %{tarif}', { tarif: subscription.subscription_plan.name }) }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<style lang="scss" scoped>
.welcome-card-title {
  background-color: rgba(var(--v-theme-primary), var(--v-table-header-opacity));
}

.welcome-dialog {
  width: auto;
  max-width: 584px;
}

.welcome-card-text {
  color: rgb(var(--v-theme-primary));
}

.welcome-card-actions {
  border-top: 1px solid rgb(var(--v-theme-on-surface));
}
</style>

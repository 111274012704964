import { defineStore } from 'pinia'
import type { State } from './types'
import { useEventBus } from '@vueuse/core'
import { useSubscription } from '@/composables/subscription/subscription'
import type { DashboardSubscription } from '@/api/types.gen'

const bus = useEventBus<DashboardSubscription[]>('updateSubscriptionsList')

export const useSubscriptionStore = defineStore('subscription-store', {
  state: (): State => ({
    subscriptions: [],
    subscription: undefined,
  }),
  getters: {
    getSubscriptions: state => state.subscriptions,
    getCurrentSubscription: state => state.subscription,
  },
  actions: {
    async fetchSubscriptions() {
      const { subscriptionList } = useSubscription()
      
      return subscriptionList().then(response => {
        this.subscriptions = response
        bus.emit(response)
        
        return response
      }).catch(err => {
        console.log('Could not fetch subscriptions due to the following error: ', err.body)

        throw err
      })
    },
    async fetchSubscription() {
      const { subscriptionRetrieve } = useSubscription()

      return subscriptionRetrieve().then(response => {
        this.subscription = response as DashboardSubscription
        
        return this.subscription
      }).catch(err => {
        console.log('Could not fetch subscription due to the following error: ', err.body)
        
        return undefined
      })
    },
  },
})

import { German } from 'flatpickr/dist/l10n/de'
import md5 from 'md5'
import { english } from 'flatpickr/dist/l10n/default'
import { isToday } from '@core/utils/helpers'
import type {
  DashboardConfigUser,
  DashboardFeatureFlag,
  DashboardLocation, DashboardSessionLocation,
  DashboardSessionWorker,
  DashboardSubscription,
  DashboardUser,
  DashboardWorker,
  Profile,
} from '@/api/types.gen'
import type { RouteLocationNormalizedLoadedGeneric, Router } from 'vue-router'
import _ from 'lodash'

const { $gettext, $ngettext } = gettext

// Should enable rendereing h() outputs to strings
export const renderToString = (vnode: any): string => {
  const app = createApp({
    render: () => vnode,
  })

  const root = document.createElement('div')

  app.mount(root)
  
  return root.innerHTML
}

export const appendQuery = (router: Router, route: RouteLocationNormalizedLoadedGeneric, queryType: string, val: string | number | string[] | number[]) => {
  router.push({ path: route.fullPath, query: { ...route.query, ...{ [queryType]: val || undefined } } })
}

export const redirectToNotAuthorized = (router: Router) => {
  router.push({
    name: 'errors',
    query: {
      errorIcon: 'error-not-authorized',
    },
  })
}

export const componentToHex = (c: number) => {
  var hex = c.toString(16)

  return hex.length == 1 ? '0' + hex : hex
}

export const rgbToHex = (r: number, g: number, b: number) => {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b)
}

export const hexToRGB = (hex: string, alpha?: number) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  if (alpha)
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  else
    return `rgb(${r}, ${g}, ${b})`
}

export const setCookie = (cname: string, cvalue: string, exdays: number) => {
  const d = new Date()

  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))

  const expires = `expires=${d.toUTCString()}`

  document.cookie = `${cname}=${cvalue};${expires};path=/`
}

export const getCookie = (cname: string) => {
  const name = `${cname}=`
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ')
      c = c.substring(1)

    if (c.indexOf(name) === 0)
      return c.substring(name.length, c.length)
  }

  return ''
}

export const getUserLocale = () => {
  let userLocale = navigator.language
  if (navigator.language.includes('-'))
    userLocale = navigator.language.split('-')[0]

  return userLocale
}

export const datePickerConfig = () => {
  let config = {}
  const now = new Date()

  if (navigator.language) {
    const userLanguage = getUserLocale()

    if (userLanguage === 'de') {
      gettext.current = userLanguage
      config = { 
        enableTime: true, 
        dateFormat: "Y-m-d H:i", 
        altFormat: 'j. M Y, H:i', 
        altInput: true, 
        allowInput: true, 
        locale: German, 
        defaultDate: now,
        time_24hr: true, 
      }
    }
    else {
      config = { 
        enableTime: true, 
        dateFormat: "Y-m-d H:i",
        altInput: true, 
        locale: english,
        defaultDate: now,
      }
    }
  }

  return config
}

export const calculateDuration = (epoch: number) => {
  const days = Math.floor(epoch / 1000 / 60 / 60 / 24)
  const hours = Math.floor(epoch / 1000 / 60 / 60) % 24
  const minutes = Math.floor(epoch / 1000 / 60) % 60
  const seconds = Math.floor(epoch / 1000) % 60
  var result = ''
  if (days > 0) result += `${days}d `
  if (hours > 0) result += `${hours}h `
  if (minutes > 0) result += `${minutes}m `
  if (seconds > 0) result += `${seconds}s`

  return result
}

export const convertNewlinesToBreakTags = (input: string) => {
  return input.replace(/\r?\n/g, '<br />')
}

export const resolveTechnicianStatusVariantColor = (stat: string | boolean) => {
  if (stat === 'approved')
    return 'avatar-color'
  
  return resolveStatusVariantColor(stat)
}

export const resolveStatusVariantColor = (stat: string | boolean) => {
  if (stat === 'approved' || stat === 'ended' || stat === true)
    return 'success'
  if (stat === false)
    return 'secondary'
  if (stat === 'rejected' || stat === 'overdue' || stat === 'deactivated' || stat === 'deleted')
    return 'error'
  if (stat === 'running' || stat === 'active')
    return 'primary'
  if (stat === 'canceled' || stat === 'paused' || stat === 'inactive')
    return 'on-surface'
  if (stat === 'pending')
    return 'warning'

  return 'primary'
}

export const resolveSessionStatusIcon = (status: string) => {
  if (status === 'ended')
    return 'feather-check-circle'
  if (status === 'canceled')
    return 'feather-x-circle'
  if (status === 'overdue')
    return 'feather-alert-triangle'
}

export const isDaysAgo = (date: string | undefined, days: number) => {
  if (!date)
    return false
  const now: Date = new Date()
  const dateTime: Date = new Date(date)
  const diff: number = now.getTime() - dateTime.getTime()

  const daysDiff: number = Math.floor(diff / (1000 * 60 * 60 * 24))

  return daysDiff >= days
}

export const getDaysLeft = (subscription: DashboardSubscription) => {
  if (!subscription.end_date)
    return $gettext("Unlimited")
  const now = new Date()
  const endDate = new Date(subscription.end_date)
  const diff = endDate.getTime() - now.getTime()
  const daysLeft = Math.ceil(diff / (1000 * 60 * 60 * 24))

  return daysLeft
}

export const getRelativeTime = (dateTimeString: string | undefined): string => {
  if (!dateTimeString)
    return ''
  const now: Date = new Date()
  const dateTime: Date = new Date(dateTimeString)
  const diff: number = now.getTime() - dateTime.getTime()

  // Converting to minutes, hours and days
  const minutes: number = Math.floor(diff / (1000 * 60))
  const hours: number = Math.floor(diff / (1000 * 60 * 60))
  const days: number = Math.floor(diff / (1000 * 60 * 60 * 24))

  // return dependent on difference
  if (days >= 2) {
    // If more than 2 days, return the date in the format of 02 August 2021
    return getDate(dateTimeString)
  }
  else if (days >= 1) {
    return $ngettext('%{days} day ago', '%{days} days ago', days, { days: days.toString() })
  }
  else if (hours >= 1) {
    return $ngettext('%{hours} hour ago', '%{hours} hours ago', hours, { hours: hours.toString() })
  }
  else {
    return $ngettext('%{minutes} minute ago', '%{minutes} minutes ago', minutes, { minutes: minutes.toString() })
  }
}

export const getDate = (date: string) => {
  const formatting: Record<string, string> = { day: '2-digit', month: 'long', year: 'numeric' }

  return new Intl.DateTimeFormat(getUserLocale(), formatting).format(new Date(date))
}

export const formatDateTime = (date: string) => {
  const formatting: Record<string, string> = { day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' }

  return new Intl.DateTimeFormat(getUserLocale(), formatting).format(new Date(date))
}

export const formatDateTimeShort = (time: string | undefined): string => {
  if (!time)
    return ''
  const t = new Date(time)

  const dateStr = t.toLocaleDateString(getUserLocale(), { dateStyle: 'short' }).substring(0, 5)
  const timeStr = t.toLocaleTimeString(getUserLocale(), { hour: '2-digit', minute: '2-digit' })

  return `${timeStr}, ${dateStr}`
}

export const getDisplayDateFormat = (value: string, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  const today = new Date()

  // Check if the year is different for past dates
  const isPastDate = date < today
  const isSameYear = date.getFullYear() === today.getFullYear()

  if (toTimeForCurrentDay && isToday(date))
    return ''

  if (isPastDate && !isSameYear) {
    const formatting: Record<string, string> = { day: '2-digit', month: '2-digit', year: '2-digit' }

    return new Intl.DateTimeFormat(getUserLocale(), formatting).format(date)
  }
  else {
    const formatting: Record<string, string> = { day: '2-digit', month: '2-digit' }

    return new Intl.DateTimeFormat(getUserLocale(), formatting).format(date)
  }
}

export const fullName = (input?: DashboardWorker | DashboardSessionWorker | DashboardConfigUser | DashboardUser | Profile) => {
  return [input?.first_name, input?.last_name].filter(Boolean).join(' ')
}

export const infoBoxSubtext = (input?: DashboardWorker | DashboardSessionWorker | DashboardConfigUser | DashboardUser | Profile, hideCompany?: boolean) => {
  var email = input?.email
  if (input && input.email && isTemporaryWorker(input.email)) email = $gettext("Technician without App")
  
  return [!hideCompany && (input as DashboardWorker | DashboardSessionWorker)?.company, email].filter(Boolean).join(' - ')
}

export const resolveGroupNameColorAndIcon = (groupName: string) => {
  if (groupName === 'session_read_only' || groupName === 'Readonly Sessions' || groupName === 'Zutritts-Betrachter')
    return { color: 'session-read-only-color', icon: 'feather-eye' }
  if (groupName === 'admin' || groupName === 'Admin')
    return { color: 'admin-color', icon: 'feather-settings' }
  if (groupName === 'session_only' || groupName === 'Only Sessions' || groupName === 'Zutritts-Bearbeiter')
    return { color: 'session-only-color', icon: 'feather-edit-2' }

  return { color: 'primary', icon: 'feather-user' }
}

export const fullLocation = (location?: DashboardLocation | DashboardSessionLocation) => {
  return [location?.street, location?.postcode, location?.city].filter(Boolean).join(', ')
}

const convertBlobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onloadend = () => {
      if (typeof reader.result === 'string')
        resolve(reader.result)
      else
        reject(new Error('Error converting Blob to base64'))
    }
    reader.readAsDataURL(blob)
  })
}

export const gravatar = async (email: string | null | undefined): Promise<string> => {
  if (!email)
    return ''

  const trimmedEmail = email.trim().toLowerCase()
  const hash = md5(trimmedEmail)
  const gravatarURL = `https://www.gravatar.com/avatar/${hash}?d=404`

  try {
    const response = await fetch(gravatarURL)

    if (response.ok) {
      const blob = await response.blob()

      return await convertBlobToBase64(blob)
    }
    else {
      return ''
    }
  }
  catch (error) {
    console.error('Error fetching Gravatar image:', error)

    return ''
  }
}

export const getStatusText = (status: string, end_at_expected?: string) => {
  switch (status) {
  case 'active':
    return $gettext('Active')
  case 'inactive':
    return $gettext('Inactive')
  case 'ended':
    return $gettext('Finished')
  case 'canceled':
    return $gettext('Canceled')
  case 'paused':
    return $gettext('Paused')
  case 'running':
  case 'overdue':
    if (!end_at_expected)
      return $gettext(status === 'running' ? 'Running' : 'Overdue')
    let timeLeft = Math.round((new Date(end_at_expected).getTime() - (new Date()).getTime()) / 60000)
    let timeLeftString = ''
    if (timeLeft <= 0)
      timeLeftString = '- '

    timeLeft = Math.abs(timeLeft)

    return $ngettext('%{timeLeft} Min', '%{timeLeft} Mins', timeLeft, { timeLeft: timeLeftString + timeLeft.toString() })
  case 'approved':
    return $gettext('Approved')
  case 'rejected':
    return $gettext('Rejected')
  case 'deactivated':
    return $gettext('Deactivated')
  case 'pending':
    return $gettext('Pending')
  case 'deleted':
    return $gettext('Deleted')
  default:
    return status
  }
}

export const getTimeDiff = (startTime: string | undefined, endTime: string): string => {
  if (!startTime)
    return ''
  const start = new Date(startTime)
  const end = new Date(endTime)

  const diff = end.getTime() - start.getTime()
  const hours = Math.floor(diff / 1000 / 60 / 60)
  const minutes = Math.floor(diff / 1000 / 60) % 60
  if (hours === 0)
    return $gettext('%{minute} min', { minute: minutes.toString() }, true)

  return $gettext('%{hour}:%{minute} h', { hour: hours.toString(), minute: minutes.toString() }, true)
}

export const timeDurationString = (start: string, end: string): string => {
  const startDate = new Date(start)
  const endDate = new Date(end)
  const startTime = formatTime(start)
  const endTime = formatTime(end)
  if (startDate.getDay() !== endDate.getDay()) {
    const days = Math.abs(endDate.getDate() - startDate.getDate())
    const daysString = $ngettext('%{days} day later', '%{days} days later', days, { days: days.toString() })

    return `${startTime} - ${endTime} (${daysString})`
  }
  else {
    return `${startTime} - ${endTime}`
  }
}

export const formatTime = (time: string): string => {
  const t = new Date(time)

  return t.toLocaleTimeString(getUserLocale(), { hour: '2-digit', minute: '2-digit' })
}

export const workerIsAnonymous = (worker: DashboardWorker): worker is DashboardWorker => {
  if (!worker)
    return true

  return (worker.first_name === '-' || worker.first_name === '') && (worker.last_name === '-' || worker.last_name === '')
}

export const getTimeDuration = (startTime: string | undefined, endTime: string) => {
  if (!startTime || !endTime)
    return 0
  const start = new Date(startTime)
  const end = new Date(endTime)

  return end.getTime() - start.getTime()
}

export const getDurationInHours = (duration: number): number => {
  return Math.floor(duration / 1000 / 60 / 60)
}

export const getDurationInMinutes = (duration: number): number => {
  return Math.floor(duration / 1000 / 60)
}

export const getDurationInSeconds = (duration: number): number => {
  return Math.floor(duration / 1000)
}

export const convertArrToRgba = (arr: number[]): string => {
  if (arr.length < 3 || arr.length > 4)
    return ''
  if (arr.length === 3) { return `rgb(${arr[0]} ${arr[1]} ${arr[2]})` }
  else {
    const alpha = arr[3] * 100

    return `rgb(${arr[0]} ${arr[1]} ${arr[2]} / ${alpha}%)`
  }
}

export const isTemporaryWorker = (email: string): boolean => {
  if(!email) return false
  
  return email.startsWith("temporary-worker-") && email.endsWith("@entry.conntac.net")
}

export function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number,
): (...args: Parameters<T>) => void {
  let timeout: ReturnType<typeof setTimeout> | null = null
  
  return (...args: Parameters<T>): void => {
    if (timeout !== null) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      func(...args)
    }, wait)
  }
}


export function getFeatureValue(feature: DashboardFeatureFlag): number | string | boolean {
  if (feature && feature.condition === 'maximum_amount') {
    const value = feature.value?.split(',')[1] ?? '0'
    if (value === 'inf')
      return 'inf'
    
    return Number(value)
  } else if (feature) {
    return feature.value ?? false
  } else {
    return false
  }
}

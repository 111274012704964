import { subscriptionManagementSubscriptionsActiveRetrieve, subscriptionManagementSubscriptionPlansRetrieve, subscriptionManagementSubscriptionsList } from '@/api/sdk.gen'
import type { DashboardSubscription, DashboardSubscriptionPlan } from '@/api/types.gen'

const { $gettext } = gettext

export function useSubscription() {

  async function subscriptionList() {
    return subscriptionManagementSubscriptionsList().then((res: Array<DashboardSubscription>) => {
      return res
    }).catch((err: any) => {
      throw err
    })
  }
  async function subscriptionRetrieve() {
    return subscriptionManagementSubscriptionsActiveRetrieve().then((res: DashboardSubscription) => {
      return res
    }).catch((err: any) => {
      throw err
    })
  }

  return { subscriptionList, subscriptionRetrieve }
}

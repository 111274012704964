<script setup lang="ts">
import { useSubscriptionStore } from '@/store/subscription-store/useSubscriptionStore'
import { useRouter } from 'vue-router'

const { $gettext } = gettext
const subscriptionStore = useSubscriptionStore()
const subscription = computed(() => subscriptionStore.subscription)
const router = useRouter()

const isTrial = computed(() => {
  return subscription.value?.is_trial
})

onMounted(async () => {
  if (!subscription.value) {
    await subscriptionStore.fetchSubscription()
  }
})

const handleClick = () => {
  router.push('/settings?tab=1')
}
</script>

<template>
  <VAlert
    v-if="isTrial"
    variant="flat"
    height="40px"
    class="subscription-trial-banner"
    @click="handleClick"
  >
    <VIcon
      icon="feather-info"
      size="18"
      style="margin-right: 6px;"
    />
    <span style="font-weight: 700">
      {{ $gettext('The 14-day trial version will be reset to the free plan on %{date}.', { date: (new Date(subscription?.end_date ?? '')).toLocaleDateString() }) }}
    </span>
    {{ $gettext('Click here to switch to the %{plan} plan permanently.', { plan: subscription?.subscription_plan.name ?? '' }) }}
  </VAlert>
</template>

<style lang="scss" scoped>
.subscription-trial-banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
  border-radius: 0px;
  color: white;
  background-color: rgb(var(--v-theme-primary));

  &:hover {
    cursor: pointer;
  }
}
</style>

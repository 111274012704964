<script setup lang="ts">
interface Props {
  color?: string
  icon?: string
  iconSize?: string
}

const props = withDefaults(defineProps<Props>(), {
  icon: 'feather-x',
  iconSize: '20',
})
</script>

<template>
  <IconBtn
    variant="elevated"
    size="30"
    :ripple="false"
    class="v-dialog-close-btn"
  >
    <VIcon
      :color="props.color || 'on-surface'"
      :icon="props.icon"
      :size="props.iconSize"
    />
  </IconBtn>
</template>

<style scoped>
.v-dialog {
  .v-dialog-close-btn {
    color: rgba(var(--v-theme-on-surface)) !important;
  }
}
</style>
